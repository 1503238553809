<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/> 
		<!-- <Load v-if="isLoading"></Load> -->

		<div v-if="isText" class="content-text">
			<p>{{ beacon.locale.content }}</p>
		</div>
		
		<div v-if="isImage" class="content-image">
			<div class="container">
				<img class="bg-image" :src="beacon.background" alt="">
			</div>
			
			<img class="resource-image" :src="beacon.resource.url" alt="">
		</div>
		
		<div v-if="isAudio" class="content-audio">
			<div class="container">
				<img class="bg-image" :src="beacon.background" alt="">
			</div>
			
			<div class="artwork-information">
				<img class="artwork" :src="beacon.locale.url" alt="">
				
				
				<audio-player v-if="beacon.locale.url"
				              :file="beacon.locale.url"/>
			</div>
		</div>
		
		<div v-if="isVideo " class="content-video">
			<video controls>
				<source :src="beacon.locale.url" type="video/mp4">
			</video>
		</div>
		
		<footer>
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-close big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import AudioPlayer from "@/components/AudioPlayer";
// import Load from '../../components/Load.vue';


export default {
	name: "Beacon",
	components: {
		// Load,
		Loading,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idBeacon: this.$route.params.idBeacon || "0"
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			beacon: state => state.beacon.beacon,
			beaconError: state => state.beacon.beaconError
		}),
		
		isText() {
			return this.beacon && this.beacon.hasOwnProperty('locale') && this.beacon.locale && this.beacon.resource.resourceType === 'TEXT' ;
		},
		
		isAudio() {
			return this.beacon && this.beacon.hasOwnProperty('locale') && this.beacon.locale && this.beacon.resource.resourceType === 'AUDIO';
		},
		
		isVideo() {
			return this.beacon && this.beacon.hasOwnProperty('locale') && this.beacon.locale && this.beacon.resource.resourceType === 'VIDEO';
		},
		
		isImage() {
			return this.beacon && this.beacon.hasOwnProperty('resource') && this.beacon.resource && this.beacon.resource.resourceType === 'IMAGE';
		},
		pageTitle() {
			return (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
		}
	},
	
	created() {
		debug.log("[Resource] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.getBeacon(this.idBeacon);
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				})
				.catch(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('museum', ['loadMuseum']),
		...mapActions('beacon', ['getBeacon']),
		
		goBack() {
			this.$router.go(-1);
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	.content-text {
		h1 {
			font-weight: normal;
			text-transform: uppercase;
			border-bottom: 1px solid $gray-lightest;
			padding: 20px;
			width: 100%;
			position: fixed;
			top: 0;
			background-color: white;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		p {
			color: $gray-light;
			font-size: 22px;
			padding: 80px 20px 110px 20px;
		}
	}
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		left: 0;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	.content-image {
		display: flex;
		justify-content: center;
		
		.resource-image {
			padding: 20px;
			width: 100%;
			height: auto;
		}
	}
	
	.content-audio {
		.artwork-information {
			padding: 20px 20px 110px 20px;
			color: white;
			display: flex;
			flex-direction: column;
			height: calc(100% - 90px);
			
			.artwork {
				padding: 20px;
				width: 100%;
				height: auto;
			}
			
			h2 {
				font-weight: normal;
				font-size: 24px;
				margin-bottom: 10px;
				border-bottom: 1px solid $gray-light;
			}
		}
	}
	
	.content-video {
		width: 100%;
		height: calc(100vh - 90px);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: black;
		
		video {
			width: 100%;
			height: auto;
			max-height: 100%;
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
		}
	}
}
</style>
